import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'common';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService,
              private router: Router) {
  }

  canActivate(_: ActivatedRouteSnapshot, _2: RouterStateSnapshot): Observable<boolean> {
    return this.authService.isLogged()
      .pipe(
        map(logged => {
          if (!logged) {
            this.router.navigate(['/authentication/login']);
          }
          return logged;
        }),
      );
  }

  canLoad(_: Route, _2: UrlSegment[]): Observable<boolean> {
    return this.authService.isLogged()
      .pipe(
        map(logged => {
          if (!logged) {
            this.router.navigate(['/authentication/login']);
          }
          return logged;
        }),
      );
  }

}
