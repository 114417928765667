import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { UrlService } from "common";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-search-execution-page',
  templateUrl: 'search-execution.page.html',
  styleUrls: ['search-execution.page.scss'],
})
export class SearchExecutionPage implements OnInit {

  form: FormGroup;
  loading = false;
  errorMessage: string;

  constructor(private formBuilder: FormBuilder,
              private httpClient: HttpClient,
              private translateService: TranslateService,
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      certificateCode: ['', [Validators.required]],
    });
    const params = UrlService.getParams(window.location.href);
    if (params.has('code')) {
      this.getFormController('certificateCode').setValue(params.get('code'));
    }
  }

  getFormController(name: string): FormControl {
    return this.form.get(name) as FormControl;
  }

  submitForm() {
    this.errorMessage = null;
    this.loading = true;
    let certificateCode = this.getFormController('certificateCode').value?.toUpperCase() || '';


    const url = `https://${environment.awsPublicBucket}.s3.eu-west-3.amazonaws.com/${certificateCode}.pdf`;
    this.httpClient.head(url)
      .pipe(catchError((error) => {
        this.errorMessage = this.translateService.instant('page.search-instruction-execution.documentNotFound');
        this.loading = false;
        throw error;
      }))
      .subscribe(() => {
        const a = document.createElement('a');
        a.href = url;
        a.download = certificateCode + '.pdf';
        a.click();
        this.loading = false;
      });
  }

}
