import {
    PredictionApiService,
    PredictionDto,
    PredictionServiceInterface,
} from "common";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class PredictionService implements PredictionServiceInterface {

    constructor(private predictionApiService: PredictionApiService,
    ) {
    }

    getPredictions(entityType: string): Observable<PredictionDto[]> {
        return this.predictionApiService.getPredictions({
            elementType: entityType,
        });
    }

}
