import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ImageCropperComponent } from 'ngx-image-cropper';
import {
  ImageCacheService,
  Picture,
  PictureDto,
  PictureWithFlagDto,
  ResourceServiceInterface
} from 'common';
import { ModalController } from '@ionic/angular';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-select-picture-modal',
  templateUrl: 'select-picture-modal.component.html',
  styleUrls: ['select-picture-modal.component.scss'],
})
export class SelectPictureModalComponent implements OnInit {
  @ViewChild('finalImageContainer') finalImageContainer: ElementRef;
  @ViewChild('cropper') cropper: ImageCropperComponent;
  @Input() subjectResult: Subject<Picture>;
  @Input() originalStorageName?: string;
  @Input() pictureToUpdate: PictureDto | undefined;
  @Input() flagIconName?: string;
  @Input() withFlag = false;
  @Input() pictureHeight: number;
  @Input() pictureWidth?: number;
  @Input() sourceName?: string;
  _type: 'png' | 'jpeg' = 'jpeg';
  currentStep: 'selection' | 'crop' | 'flag' = 'selection';
  originalImage: string | null = null;
  errorMessage: string | null = null;
  picture = {} as PictureWithFlagDto;
  base64: string | undefined;
  blobUrl: string | undefined;
  imageRotation = 0;

  constructor(private cdr: ChangeDetectorRef,
              private imageCacheService: ImageCacheService,
              private modalController: ModalController,
              @Inject('ResourceService') private resourceService: ResourceServiceInterface,
  ) {
  }

  @Input() set type(value: 'png' | 'jpeg' | undefined) {
    this._type = !!value ? value : this._type;
  }

  ngOnInit(): void {
    if (this.withFlag) {
      if (this.pictureToUpdate) {
        this.currentStep = 'flag';
        // clone
        this.picture = JSON.parse(JSON.stringify(this.pictureToUpdate));
        this.imageCacheService.getBlobUrl(this.pictureToUpdate.storageName)
          .subscribe(url => this.blobUrl = url);
      }

      if (this.flagIconName) {
        this.picture.flag = {
          x: '50%',
          y: '50%',
          iconName: this.flagIconName
        };
      }
      if (!this.picture.flag) {
        this.resourceService.findResourceItemByListNameAndName('divers', 'ORGANFLAGDEFAULTICON')
          .subscribe(resource => this.picture.flag = {
            x: '50%',
            y: '50%',
            iconName: resource.illustrationPath?.storageName
          });
      }
    }
    if (!this.picture.storageName) {
      if (this.originalStorageName) {
        this.picture.storageName = this.originalStorageName;
      } else {
        this.picture.storageName = crypto.randomUUID() + '.' + this._type;
      }
    }
  }

  close() {
    this.modalController.dismiss();
  }

  handlerFileFromDragAndDrop(files: File[]) {
    // @ts-ignore
    const image = files[0];
    if (image.type.indexOf('image') !== 0) {
      this.errorMessage = 'not an image';
    } else {
      this.getBase64(image)
        .subscribe(() => this.validateSelection());
    }
  }

  handleFileInput(eventTarget: EventTarget) {
    // @ts-ignore
    const image = eventTarget.files.item(0);
    if (image.type.indexOf('image') !== 0) {
      this.errorMessage = 'not an image';
    } else {
      this.getBase64(image)
        .subscribe(() => this.validateSelection());
    }
  }

  getBase64(file): Observable<void> {
    return new Observable<void>(subscriber => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.originalImage = reader.result as string;
        subscriber.next();
        subscriber.complete();
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
        subscriber.error(error);
      };
    });
  }

  validateCrop() {
    this.base64 = this.cropper.crop().base64;
    this.currentStep = 'flag';
  }

  validateCropAndSubmit() {
    this.validateCrop();
    this.submit();
  }

  moveFlag(ev: MouseEvent) {
    if (ev.buttons === 1 || ev.type === 'click') {
      const containerHeight = this.finalImageContainer.nativeElement.clientHeight;
      const containerWidth = this.finalImageContainer.nativeElement.clientWidth;
      const x = ev.offsetX;
      const y = ev.offsetY;
      if (x > containerWidth * 0.05 && x < containerWidth * 0.95 &&
        y > containerHeight * 0.05 && y < containerHeight * 0.95) {
        const newX = (x / containerWidth * 100);
        const newY = 100 - (y / containerHeight * 100);
        this.picture.flag.x = newX + '%';
        this.picture.flag.y = newY + '%';
        this.cdr.detectChanges();
      }
    }
  }

  submit(ignoreFlag: boolean = false) {
    if (ignoreFlag || !this.withFlag) {
      this.picture.flag = undefined;
    }
    // convert to file
    let blobUrl: string;
    if (this.blobUrl) {
      blobUrl = this.blobUrl;
    } else {
      const blob = this.b64toBlob(this.base64 as string, 'image/' + this._type);
      blobUrl = URL.createObjectURL(blob);
      this.picture.weight = blob.size;
    }
    this.imageCacheService.insert(this.picture.storageName, this.sourceName || 'selectPicture', blobUrl);
    const picture = Picture.fromDto(blobUrl, this.picture);
    this.subjectResult.next(picture);
    this.close();
  }

  backToStep(expectedStep: string) {
    if (expectedStep === 'selection' && this.currentStep !== 'selection') {
      this.currentStep = 'selection';
    }
    if (expectedStep === 'crop' && this.currentStep === 'flag') {
      if (this.originalImage) {
        this.currentStep = 'crop';
      }
    }
  }

  previousStep() {
    if (this.currentStep === 'flag') {
      this.currentStep = 'crop';
    } else if (this.currentStep === 'crop') {
      this.currentStep = 'selection';
    }
  }

  validateSelection() {
    this.currentStep = 'crop';
  }

  b64toBlob(b64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }
}
