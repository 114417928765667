import { Injectable } from '@angular/core';
import { FileSystemServiceInterface } from 'common';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileSystemService implements FileSystemServiceInterface {

  constructor() {
  }

  download(data: {
    source: {
      // only one
      blobUrl?: string,
      textContent?: string,
    }
    fileName: string
  }): Observable<any> {
    const a = document.createElement('a');
    a.href = data.source.blobUrl;
    a.download = data.fileName;
    a.click();
    return of(void 0);
  }

  openPdf(base64: string, fileName: string): Observable<any> {
    return this.download({source: {blobUrl: base64}, fileName});
  }
}
