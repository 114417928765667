import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService, UserApiService, UserDto, UserServiceInterface } from 'common';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class UserService implements UserServiceInterface {
  private static userStorageKey = 'user';

  constructor(private router: Router,
              private userApiService: UserApiService,
              private storageService: StorageService) {
  }

  // Override
  refreshUser(): Observable<any> {
    return this.findMyself()
      .pipe(
        mergeMap((user) => {
          if (!user) {
            return this.userApiService.getMyself()
              .pipe(mergeMap((myself: UserDto) => this.saveMyself(myself)));
          }
          return of(void 0);
        })
      );
  }

  // Override
  saveMyself(user: UserDto): Observable<void> {
    this.saveUser(user);
    return of(void 0);
  }

  // Override
  saveUser(user: UserDto): Observable<void> {
    this.storageService.storeItem(UserService.userStorageKey, JSON.stringify(user));
    return of(void 0);
  }

  // Override
  hasMyself(): Observable<boolean> {
    return of(this.storageService.hasItem(UserService.userStorageKey));
  }

  // Override
  getMyself(): Observable<UserDto> {
    return this.findMyself()
      .pipe(
        tap(user => {
          if (!user) {throw new Error('user not found');}
        })
      );
  }

  // Override
  getMyselfUserName(): Observable<string> {
    return this.getMyself()
      .pipe(
        map((user: UserDto) => user.firstName + ' ' + user.lastName)
      );
  }

  // Override
  clearMyself(): Observable<void> {
    this.storageService.cleanAll();
    return of(void 0);
  }

  private findMyself(): Observable<UserDto | undefined> {
    const user = this.storageService.getItem(UserService.userStorageKey);
    if (user) {
      return of(JSON.parse(user) as UserDto);
    }
    return of(undefined);
  }
}
