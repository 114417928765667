import { Injectable } from "@angular/core";
import {
  InstructionExecutionApiService,
  InstructionExecutionDto,
  InstructionExecutionServiceInterface,
} from "common";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class InstructionExecutionService implements InstructionExecutionServiceInterface {
  constructor(private instructionExecutionApiService: InstructionExecutionApiService,
  ) {
  }

  getById(id: string): Observable<InstructionExecutionDto> {
    return this.instructionExecutionApiService.getInstructionExecution({instructionExecutionId: id});
  }

  findInProcessingByInstructionIdAndExecutorId(_: string, _2: string): Observable<InstructionExecutionDto | undefined> {
    throw Error("not implemented");
  }
}
