import { Injectable } from '@angular/core';
import {
  FactoryApiService,
  FactoryComponentDto,
  FactoryFolderDto,
  FactoryFolderServiceInterface
} from 'common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FactoryFolderService implements FactoryFolderServiceInterface {

  constructor(private factoryApiService: FactoryApiService) {
  }

  updateFactoryFolder(factoryFolder: FactoryFolderDto): Observable<any> {
    return this.factoryApiService.updateFactoryFolder({
      folderId: factoryFolder.id,
      body: factoryFolder
    });
  }

  removeFactoryFolder(factoryFolder: FactoryFolderDto): Observable<any> {
    return this.factoryApiService.deleteFactoryFolder({
      folderId: factoryFolder.id
    });
  }

  findFirstParentEquipment(component: FactoryComponentDto): Observable<FactoryFolderDto | undefined> {
    return this.factoryApiService.findFirstParentEquipment({
      factoryComponentId: component.id,
    });
  }
}
