import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NetworkServiceInterface, NetworkStatus } from 'common';


@Injectable({
  providedIn: 'root'
})
export class NetworkService implements NetworkServiceInterface {

  getNetwork(): Observable<NetworkStatus> {
    return of({
      connectionType: 'wifi',
      connected: true
    });
  }

  listenNetwork(): Observable<NetworkStatus> {
    return of({
      connectionType: 'wifi',
      connected: true
    });
  }

  isOnline(): Observable<boolean> {
    return of(true);
  };

  forceNetwork(newStatus: NetworkStatus) {
  }
}
