import {
  AscendantDto,
  FactoryApiService,
  FactoryComponentDto,
  FactoryStructureLeafDto,
  FactoryStructureServiceInterface
} from 'common';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FactoryStructureService implements FactoryStructureServiceInterface {

  constructor(private factoryApiService: FactoryApiService) {
  }

  getFactoryTree(withOrgans?: boolean, withInstructions?: boolean): Observable<FactoryStructureLeafDto[]> {
    return this.factoryApiService.getFactoryTree({
      readOnly: true,
      withOrgans: withOrgans || false,
      withInstructions: withInstructions || false,
    });
  }

  getParentsChainFormLastVersion(component: FactoryComponentDto, readOnly: boolean): Observable<AscendantDto[]> {
    return this.factoryApiService.getFactoryComponentLastVersionAscendants({
      factoryComponentReference: component.identifier,
      readOnly
    });
  }
}
