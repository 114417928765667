import { Injectable } from '@angular/core';
import {
  ResourceApiService,
  ResourceListDto,
  ResourceListItemDto,
  ResourceListWithItemDto,
  ResourceServiceInterface,
} from 'common';
import { Observable, of, Subject } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ResourceService implements ResourceServiceInterface {
  private static serverCalled = false;
  private static waitingObservableMap: Map<Subject<ResourceListWithItemDto>, string> = new Map();
  private static resources: Map<string, ResourceListWithItemDto> | undefined;

  constructor(private resourceApiService: ResourceApiService) {
  }

  getItems(listReference: string, prefix?: string): Observable<ResourceListItemDto[]> {
    if (!ResourceService.resources) {
      if (!ResourceService.serverCalled) {
        // request list
        this.resourceApiService.getResourceLists()
          .subscribe((resources: ResourceListDto[]) => {
            ResourceService.resources = new Map<string, ResourceListDto>(resources.map(i => [i.reference, i]));
            // call all the waiting observable
            ResourceService.waitingObservableMap.forEach((v: string, k: Subject<ResourceListDto>) => {
              k.next(this.getResourceListInstant(v, prefix));
              k.complete();
            });
          });
        ResourceService.serverCalled = true;
      }
      // if the server was alredy called but the response is not yet here,
      // create a subject, store it and return it
      const sbj = new Subject<ResourceListWithItemDto>();
      // the subject must to store (only a subject call be called be 'next')
      // but the observable returned by the pipe must be returned
      ResourceService.waitingObservableMap.set(sbj, listReference);
      return sbj.pipe(map(l => l.items));
    } else {
      return of(this.getResourceListInstant(listReference, prefix))
        .pipe(map(l => l.items));
    }
  }

  findResourceItemByListNameAndName = (resourceListReference: string, resourceReference: string): Observable<ResourceListItemDto | undefined> => {
    return this.getItems(resourceListReference)
      .pipe(
        map((items: ResourceListItemDto[]) => {
          const found = items.filter((item: ResourceListItemDto) => item.reference === resourceReference);
          return found.length ? found[0] : undefined;
        })
      );
  }

  forceReload() {
    ResourceService.resources = undefined;
    ResourceService.serverCalled = false;
  }

  private getResourceListInstant(listReference: string, prefix?: string): ResourceListWithItemDto {
    if (ResourceService.resources.has(listReference)) {
      if (prefix) {
        const list = ResourceService.resources.get(listReference) as ResourceListWithItemDto;
        const items = list.items.filter(i => i.reference.startsWith(prefix + '__'));
        const clonedList = Object.assign({}, list);
        clonedList.items = items;
        return clonedList;
      }
      return ResourceService.resources.get(listReference) as ResourceListWithItemDto;
    }
    return {reference: listReference, items: []} as ResourceListWithItemDto;
  }
}
