import { Inject, Injectable } from '@angular/core';
import {
  FactoryDto,
  FileAccessServiceInterface,
  MembershipServiceInterface,
  PictureStorageService,
  SubscribableDto,
  SubscriptionApiService
} from 'common';
import { Observable } from 'rxjs';
import { MembershipService } from 'src/app/service/membership.service';
import { filter, map, mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileAccessService implements FileAccessServiceInterface {

  constructor(private pictureStorageService: PictureStorageService,
              private subscriptionApiService: SubscriptionApiService,
              @Inject('MembershipService') private membershipService: MembershipServiceInterface) {
  }

  getPicture = (factoryId: string, imagePath: string, factory?: FactoryDto): Observable<string | undefined> => {
    return factory ?
      this.pictureStorageService.getImage(factory, imagePath) :
      this.membershipService.getSelectedMembershipObs()
        .pipe(
          filter((m) => !!m),
          map((m) => m.subscribable),
          mergeMap((subscribable: SubscribableDto) => this.pictureStorageService.getImage(subscribable as FactoryDto, imagePath))
        );
  };

  uploadPicture = (factoryId: string, imagePath: string, factory?: FactoryDto): Observable<string | undefined> => {
    return this.getPicture(factoryId, imagePath, factory);
  }

  fileExists = (factoryId: string, imagePath: string): Observable<boolean> => {
    return this.membershipService.getSelectedMembershipObs()
      .pipe(
        filter((m) => !!m),
        map((m) => m.subscribable),
        mergeMap((subscribable: SubscribableDto) => this.pictureStorageService.fileExists(subscribable as FactoryDto, imagePath))
      );
  }

  getFileBase64 = (factoryId: string, imagePath: string, _: boolean): Observable<string | undefined> => {
    return this.membershipService.getSelectedMembershipObs()
      .pipe(
        filter((m) => !!m),
        map((m) => m.subscribable),
        mergeMap((subscribable: SubscribableDto) => this.pictureStorageService.getFileBase64(subscribable as FactoryDto, imagePath))
      );
  }

  storePicture = (factoryId: string, imagePath: string, blobUrl: string): Observable<any> => {
    return this.membershipService.getSelectedMembershipObs()
      .pipe(
        take(1),
        filter((m) => !!m),
        map((m) => m.subscribable),
        mergeMap((subscribable: SubscribableDto) => this.pictureStorageService.storeFile(subscribable as FactoryDto, imagePath, blobUrl))
      );
  };
}
