import { FactoryApiService, OrganApiService, OrganDto, OrganServiceInterface } from "common";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class OrganService implements OrganServiceInterface {

  constructor(private organApiService: OrganApiService,
              private factoryApiService: FactoryApiService,
  ) {
  }

  getById(id: string, _: boolean): Observable<OrganDto> {
    return this.organApiService.getOrganById({organId: id});
  }

}
