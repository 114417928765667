import { Injectable } from '@angular/core';
import { InstructionApiService, InstructionDto, InstructionServiceInterface, } from 'common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstructionService implements InstructionServiceInterface {

  constructor(private instructionApiService: InstructionApiService,
  ) {
  }

  getById(id: string): Observable<InstructionDto> {
    return this.instructionApiService.getInstructionById({instructionId: id});
  }

  getInstructionUpgraded(instruction: InstructionDto): Observable<InstructionDto> {
    return this.instructionApiService.getInstructionUpgraded({instructionReference: instruction.reference});
  }
}
